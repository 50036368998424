import * as React from "react";
import ReactDOM from "react-dom";

const createPortalAndAppendToBody = (selector: string) => {
  const $portal = document.createElement("div");
  $portal.setAttribute("id", selector);
  document.body.appendChild($portal);

  return $portal;
};

type PortalProps = {
  children: React.ReactNode;
  selector?: string;
  blockBodyScroll?: boolean;
};
const Portal = ({
  children,
  selector = "portal",
  blockBodyScroll = true,
}: PortalProps) => {
  const portal = React.useRef<HTMLDivElement | null>(null);
  const [tempNode, setTempNode] = React.useState<HTMLElement | null>(null);
  const [, forceUpdate] = React.useState<{}>({});

  React.useEffect(() => forceUpdate({}), []);

  React.useEffect(() => {
    if (!tempNode) {
      return;
    }

    const $body = document.body;

    if (blockBodyScroll) {
      $body.style.overflow = "hidden";
    }

    portal.current = createPortalAndAppendToBody(selector);
    const $portal = portal.current;

    forceUpdate({});

    return () => {
      if ($body.contains($portal)) {
        $body.removeChild($portal);
        $body.style.overflow = "";
      }
    };
  }, [tempNode, selector, blockBodyScroll]);

  return portal.current ? (
    ReactDOM.createPortal(children, portal.current)
  ) : (
    <span ref={(el) => el && setTempNode(el)} style={{ display: "none" }} />
  );
};

export default Portal;
